import { initializeApp } from "firebase/app";
import { getFirestore, collection, doc, getDoc, getDocs, addDoc, updateDoc, deleteDoc, arrayUnion, arrayRemove, query, where, onSnapshot, orderBy, documentId, deleteField, limit, startAfter, getCountFromServer } from "firebase/firestore";
import i18n from '@/plugins/i18n';
import { mapActions, mapState } from "vuex";
import { tools } from '@/mixins/tools';
import {
  getAuth, signOut, onAuthStateChanged,
  updateProfile,
  // connectAuthEmulator
} from "firebase/auth";
import { getStorage, ref, listAll, getMetadata, getDownloadURL } from "firebase/storage";
import store from '@/store';
import VIEWS from "../config/views.json";


const firebaseConfig = {
  apiKey: window.PROJECT_ENV.API_KEY,
  authDomain: window.PROJECT_ENV.AUTH_DOMAIN,
  projectId: window.PROJECT_ENV.PROJECT_ID,
  storageBucket: window.PROJECT_ENV.STORAGE_BUCKET,
  messagingSenderId: window.PROJECT_ENV.GOOGLE_APP_ID,
  appId: window.PROJECT_ENV.APP_ID,
};
const app = initializeApp(firebaseConfig);
const storage = getStorage();
const db = getFirestore(app);
const auth = getAuth();
let expirationTimeOut = undefined;

if (location.hostname === "localhost") {
  // Point to the Storage emulator running on localhost.
  // connectAuthEmulator(auth, "http://localhost:9099");
}

export const firebaseTools = {
  mixins: [tools],
  computed: {
    ...mapState(["actualView"]),
  },
  methods: {
    ...mapActions(["setShowSnackBar", "setUserLogged", "setRouteVuex"]),
    subscribeOnSnapshot(collectionName, queryActualView, saveData = true) {
      const viewName = tools.methods.collectionStore(collectionName);
      if (!store.state[viewName] || !store.state.unsubscribe[viewName]) {
        let list = [];
        const queryOnSnapshot = queryActualView
          ? query(
              collection(db, collectionName),
              where(
                queryActualView.atribute,
                queryActualView.operator,
                queryActualView.value
              ),
              where("userGroup", "==", store.state.actualGroup.id),
              orderBy(queryActualView.orderBy || "name", "asc")
            )
          : query(
              collection(db, collectionName),
              where("userGroup", "==", store.state.actualGroup.id),
              orderBy("name", "asc")
            );

          store.dispatch("setLoadingPage", true);
          const unsubscribe = onSnapshot(queryOnSnapshot, (querySnapshot) => {
            list = [];
            querySnapshot.forEach((doc) => {
              list.push({ ...doc.data(), id: doc.id });
            });
            if (saveData) {
              if (store.state.itemToSelect) {
                let newItem = list.find(
                  (element) => element.name === store.state.itemToSelect.name
                );
                store.dispatch("setItemToSelect", newItem);
              }
              const actualView =
                store.state.actualView.parent || store.state.actualView;
              store.dispatch(
                tools.methods.collectionStoreDispatcher(collectionName),
                list
              );
              if (
                !actualView.meta?.parent &&
                !actualView.parent && actualView?.get &&
                actualView?.get[0]?.collection &&
                tools.methods.collectionStore(actualView?.get[0]?.collection) ===
                  viewName
              ) {
                store.dispatch("setDataView", list);
              }
            } else store.dispatch("setVolatileData", list);
            store.dispatch("setLoadingPage", false);
          });
          store.dispatch("setUnsubscribe", { [viewName]: unsubscribe });
      }
    },
    subscribeOnSnapshotById(collectionName, queryActualView = null, documentId ) {
      this.unsubscribeElement("Detail");
      this.unsubscribeElement("DetailDictionary");

      if (!queryActualView && typeof documentId === "string") {
        const parentName = store.state.actualView?.parent?.name || store.state.actualView?.meta?.parent || undefined;

        const queryOnSnapshot = query(doc(db, collectionName, documentId));
          store.dispatch("setLoadingPage", true);

          const unsubscribe = onSnapshot(queryOnSnapshot, { includeMetadataChanges: true }, (querySnapshot) => {
              const { userGroup } = querySnapshot.data() || {};
  
              const isUserInCorrectGroup = userGroup === store.state.actualGroup.id
              const isValidOutputDefinition = parentName === "OutputDefinitionsDetail" && collectionName === "outputDescriptors"
              const isValidInputDefinition = parentName === "InputDefinitionsDetail" && collectionName === "inputDescriptors"
              const isValidDictionariesDefinition = parentName === "Dictionaries"
              const isValidDefinition = isValidOutputDefinition || isValidInputDefinition || isValidDictionariesDefinition
              if (isUserInCorrectGroup && isValidDefinition) {
                store.dispatch("setDataView", querySnapshot.data());
              } else if (store.state.isDeletedDocument && isValidDefinition) {
                store.dispatch("setDataView", querySnapshot.data());
                
                store.dispatch("setIsDeletedDocument", false);
              }
              else {
                store.dispatch("setDataView", undefined);
                store.dispatch("setRouteVuex", "Error404");
              }

              store.dispatch("setLoadingPage", false);
            }
          );
          store.dispatch("setUnsubscribe", {
            Detail: unsubscribe,
            DetailDictionary: unsubscribe,
          });
      }
    },
    subscribeOnSnapshotUser(userId) {
      const queryOnSnapshot = query(doc(db, "users", userId));
      const unsubscribe = onSnapshot(queryOnSnapshot, (querySnapshot) => {
        const newUserLogged = {
          ...store.state.userLogged,
          ...querySnapshot.data(),
        };

        store.dispatch("setUserLogged", newUserLogged);
        i18n.locale = newUserLogged.lang;
        this.$vuetify.lang.current = newUserLogged.lang;
        this.getUserGroups();
      });
      store.dispatch("setUnsubscribe", { userLogged: unsubscribe });
    },
    getUserGroups() {
      let userGroups = [];
      const queryGroups = query(
        collection(db, "groups"),
        where(documentId(), "in", store.state.userLogged.groups)
      );
      getDocs(queryGroups)
        .then((querySnapshot) => {
          querySnapshot.forEach((document) => {
            userGroups.push({ id: document.id, name: document.data().name });
          });
          store.dispatch("setUserGroups", userGroups);
          if (
            !localStorage.getItem("actualGroup") ||
            (localStorage.getItem("actualGroup") &&
              !userGroups.find(
                (group) =>
                  group.id ===
                  JSON.parse(localStorage.getItem("actualGroup")).id
              ))
          ) {
            localStorage.setItem("actualGroup", JSON.stringify(userGroups[0]));
            store.dispatch("setActualGroup", userGroups[0]);
          } else if (localStorage.getItem("actualGroup")) {
            store.dispatch(
              "setActualGroup",
              JSON.parse(localStorage.getItem("actualGroup"))
            );
          }
        })
        .catch((error) => {
          this.handleFireBaseRequests(error.code);
        });
    },
    subscribeOnSnapshotProcess() {
      const createBaseProcessQuery = (groupId) => {
        return [
          where("user.group", "==", groupId),
          orderBy("startDate", "desc"),
          orderBy("inputFileName", "desc"),
          orderBy("user.name", "desc")
        ];
      };
    
      const { processesPerPage, currentProcessPage } = store.state;
      const self = this;
    
      const processCollection = collection(db, "process");
      const baseQueryConstraints = createBaseProcessQuery(store.state.actualGroup.id);
    
      const updateDocsAndCheckSubscription = async () => {
        const totalSnapshot = query(
          processCollection,
          ...baseQueryConstraints
        );

        const countSnapshot = await getCountFromServer(totalSnapshot);
        const totalDocs = countSnapshot.data().count;
        store.dispatch("setTotalProcesses", totalDocs);
        
        const totalPages = Math.ceil(totalDocs / processesPerPage);
        if (currentProcessPage > totalPages && totalPages > 0) {
          store.dispatch("setCurrentProcessPage", totalPages);
          return false; // Indicates that a new subscription is required
        }
        return true;
      };
    
      const setupQueryAndListen = async () => {
        // TODO if the server paginates the server must filter too..
        let queryConstraints = [...baseQueryConstraints/* , limit(processesPerPage) */];
    
        if (currentProcessPage > 1) {
          const previousDocsQuery = query(
            processCollection,
            ...baseQueryConstraints,
            // TODO if the server paginates the server must filter too..
            // limit((currentProcessPage - 1) * processesPerPage)
          );
    
          const snapshot = await getDocs(previousDocsQuery);
          if (snapshot.docs.length > 0) {
            queryConstraints = [
              ...baseQueryConstraints,
              startAfter(snapshot.docs[snapshot.docs.length - 1]),
              // TODO if the server paginates the server must filter too..
              // limit(processesPerPage)
            ];
          } else if (currentProcessPage > 1) {
            store.dispatch("setCurrentProcessPage", currentProcessPage - 1);
            return;
          }
        }
    
        setupListener(query(processCollection, ...queryConstraints));
      };
    
      const setupListener = (processQuery) => {
        store.dispatch("setLoadingPage", true);
        const currentMap = new Map();
    
        const processDocChange = (change) => {
          const addedChange = change.type === "added";
          const modifiedChange = change.type === "modified";
          const removedChange = change.type === "removed";
    
          if (removedChange) {
            if (currentMap.has(change.doc.id)) {
              currentMap.delete(change.doc.id);
              return {
                type: 'removed',
                id: change.doc.id,
                needsPageUpdate: true
              };
            }
            return null;
          }
    
          const docData = {
            ...change.doc.data(),
            id: change.doc.id,
            percentage: Math.round(
              (change.doc.data().numberReadPackages * 100) / 
              change.doc.data().totalNumberPackages
            )
          };
    
          if (addedChange) {
            currentMap.set(docData.id, docData);
            return { type: 'added', data: docData, needsPageUpdate: true };
          }
    
          if (modifiedChange && currentMap.has(docData.id)) {
            const currentDoc = currentMap.get(docData.id);
            const hasStatusChanged = docData.status !== currentDoc.status;
            const hasPercentageChanged = docData.percentage !== currentDoc.percentage;
            const hasEndDateChanged = docData.endDate !== currentDoc.endDate;
    
            if (hasStatusChanged || hasPercentageChanged || hasEndDateChanged) {
              currentMap.set(docData.id, docData);
              return { type: 'modified', data: docData };
            }
          }
    
          return null;
        };
    
        const handleStatusNotifications = (docData) => {
          if (docData.status === "FINISHED") {
            self.handleFireBaseRequests("finish-process", null, null);
          } else if (docData.status === "FAIL") {
            self.handleFireBaseRequests("error-process", null, null);
          }
        };
    
        const unsubscribe = onSnapshot(processQuery, async (querySnapshot) => {
          let hasChanges = false;
          let needsPageUpdate = false;
          
          const changes = querySnapshot.docChanges()
            .map(processDocChange)
            .filter(change => change !== null);
    
          changes.forEach(change => {
            hasChanges = true;
            if (change.type === 'modified') {
              handleStatusNotifications(change.data);
            }
            if (change.needsPageUpdate) {
              needsPageUpdate = true;
            }
          });
    
          if (hasChanges) {
            const currentList = Array.from(currentMap.values())
              .sort((a, b) => {
                const startDateA = a.startDate;
                const startDateB = b.startDate;
                return startDateB.seconds !== startDateA.seconds
                  ? startDateB.seconds - startDateA.seconds
                  : startDateB.nanoseconds - startDateA.nanoseconds;
              });
    
            if (currentList.length === 0 && currentProcessPage > 1) {
              const shouldContinue = await updateDocsAndCheckSubscription();
              if (!shouldContinue) return;
              
              store.dispatch("setCurrentProcessPage", currentProcessPage - 1);
              store.dispatch("setProcessData", []);
              return;
            }
    
            store.dispatch("setProcessData", currentList);
    
            if (needsPageUpdate) {
              await updateDocsAndCheckSubscription();
            }
          }
          
          store.dispatch("setLoadingPage", false);
        });
    
        store.dispatch("setUnsubscribe", { ProcessHistory: unsubscribe });
      };
    
      setupQueryAndListen();
    },
    getAllData(collectionName, queryActualView = null) {
      let list = [];
      const queryOnSnapshot = queryActualView
        ? query(
            collection(db, collectionName),
            where(
              queryActualView.atribute,
              queryActualView.operator,
              queryActualView.value
            ),
            where("userGroup", "==", store.state.actualGroup.id)
          )
        : query(
            collection(db, collectionName),
            where("userGroup", "==", store.state.actualGroup.id)
          );
      return getDocs(queryOnSnapshot)
        .then((querySnapshot) => {
          list = [];
          querySnapshot.forEach((doc) => {
            list.push({ ...doc.data(), id: doc.id });
          });
          return list;
        })
        .catch((error) => {
          this.handleFireBaseRequests(error.code);
        });
    },
    getDataByDocumentId(collectionName, documentId) {
      return getDoc(doc(db, collectionName, documentId))
        .then((querySnapshot) => {
          return querySnapshot;
        })
        .catch((error) => {
          this.handleFireBaseRequests(error.code);
        });
    },
    insertDocument(collectionName, object, nullableFields = []) {
      store.dispatch("setItemToAddEdit", undefined);
      store.dispatch("setItemToAddEditStepper", undefined);
      function deleteNulls(obj) {
        Object.keys(obj).forEach((el) => {
          const objEl = obj[el];
          const deleteable = Boolean(
            (!nullableFields.includes(el) &&
              ([null, undefined, ""].includes(objEl) ||
                (Array.isArray(objEl) && !objEl.length))) ||
              (typeof objEl === "object" && !Object.keys.length)
          );

          if (typeof objEl === "object" && !Array.isArray(obj) && !!objEl)
            deleteNulls(objEl);
          else if (deleteable) delete obj[el];
        });
      }
      deleteNulls(object);

      return addDoc(collection(db, collectionName), object)
        .then((querySnapshot) => {
          collectionName === "process"
            ? this.handleFireBaseRequests("success-process", object.name, "add")
            : this.handleFireBaseRequests("success-CRUD", object.name, "add");
          return querySnapshot;
        })
        .catch(() => {
          this.handleFireBaseRequests("error-CRUD", object.name, "add");
        });
    },
    updateDocument(
      collectionName,
      documentId,
      object = {},
      actionSetted = undefined,
      nameNotification = null,
      deleteNullValuesParam = false
    ) {
      store.dispatch("setLoadingData", true);
      if (!nameNotification) nameNotification = object?.nameNotification;
      delete object?.nameNotification;
      let values = object;

      let itemToAddEdit =
        store.state.itemToAddEdit ||
        store.state.itemToAddEditStepper ||
        store.state.itemToConfirmAction;
      const {
        params,
        deleteNullValues = deleteNullValuesParam,
        nullableFields = [],
        isLastItemToDelete = false,
      } = itemToAddEdit?.data || {};
      const {
        msgAction = undefined,
        updateValuesFB = undefined,
        nameToNotify = undefined,
      } = params || {};
      let action = actionSetted || msgAction || "edit";

      const {
        operator = null,
        atribute = null,
        rawUpdate,
        parentAttr,
      } = updateValuesFB || {};

      function deleteNulls(obj) {
        Object.keys(obj).forEach((el) => {
          const objEl = obj[el];
          const deleteable = Boolean(
            (!nullableFields.includes(el) &&
              ([null, undefined, ""].includes(objEl) ||
                (Array.isArray(objEl) && !objEl.length))) ||
              (typeof objEl === "object" && !Object.keys.length)
          );

          if (typeof objEl === "object" && !Array.isArray(obj) && !!objEl)
            deleteNulls(objEl);
          if (deleteable && updateValuesFB) delete obj[el];
          else if (deleteable && !updateValuesFB) obj[el] = deleteField();
        });
      }

      if (deleteNullValues) deleteNulls(updateValuesFB ? object : values);
      if (updateValuesFB) {
        if (deleteNullValues) deleteNulls(object);
        if (operator === "deleteField" || isLastItemToDelete) {
          values = { [parentAttr || atribute]: deleteField() };
        } else if (operator === "arrayUnion" || operator === "both") {
          values = { [atribute]: arrayUnion(object) };
        } else if (operator === "arrayRemove" && !rawUpdate) {
          values = { [atribute]: arrayRemove(object) };
        } else if (["nestedArrayAdd", "nestedArrayEdit"].includes(operator)) {
          let dataToSet = { [parentAttr || atribute]: object };
          values = { ...dataToSet };
        }
      }

      store.dispatch("setItemToConfirmAction", undefined);
      store.dispatch("setItemToAddEdit", undefined);
      store.dispatch("setItemToAddEditStepper", undefined);

      const documentRef = doc(db, collectionName, documentId);
      const objectToReturn =
        nameNotification || nameToNotify || object.name || object.alias;
      const code =
        collectionName === "process" ? "success-process" : "success-CRUD";
      
      const forceCleanOldItem = !tools.methods.checkAreEqualObjects(itemToAddEdit?.data?.params?.oldItem, object);
      
      return updateDoc(documentRef, values)
        .then((querySnapshot) => {
          if (operator === "both" && forceCleanOldItem) {
            return updateDoc(documentRef, {
              [atribute]: arrayRemove(itemToAddEdit.data.params.oldItem),
            })
              .then((querySnapshot) => {
                this.handleFireBaseRequests(code, objectToReturn, action);
                return querySnapshot;
              })
              .catch(() => {
                this.handleFireBaseRequests(
                  "error-CRUD",
                  objectToReturn,
                  action
                );
              });
          } else {
            this.handleFireBaseRequests(code, objectToReturn, action);
            return querySnapshot;
          }
        })
        .catch(() => {
          this.handleFireBaseRequests("error-CRUD", objectToReturn, action);
        })
        .finally(() => {
          store.dispatch("setLoadingData", false);
        });
    },
    deleteDocument(collectionName, documentId, name) {
      const actualView = store.state.actualView.parent || store.state.actualView;
      store.dispatch("setItemToConfirmAction", undefined);

      store.dispatch("setIsDeletedDocument", true);
      
      return deleteDoc(doc(db, collectionName, documentId))
        .then((querySnapshot) => {
          this.handleFireBaseRequests("success-CRUD", name, "delete");
          
          if (actualView.meta && actualView.meta.parent) {
            store.dispatch("setRouteVuex", actualView.meta.parent);
          }
          
          return querySnapshot;
        })
        .catch(() => {
          this.handleFireBaseRequests("error-CRUD", name, "delete");
        });
    },
    unsubscribeElement(element) {
      if (store.state.unsubscribe[element]) {
        store.state.unsubscribe[element]();
        store.dispatch("setUnsubscribe", { [element]: undefined });
      }
    },
    createStorageReference(name) {
      return ref(storage, name);
    },
    async getFilesFromStorage(path) {
      const storageRef = this.createStorageReference(path);
      return listAll(storageRef)
      .then(async (result) => {
        const items = await Promise.all(
          result.items.map(async (item) => {
           const storageRefItem = this.createStorageReference(item.fullPath);
           const metadataFile = await getMetadata(storageRefItem);
           const {name, size} = metadataFile || {};
           return {name, size};
          })
        );
        return items;
      })
      .catch((error) => {
        this.handleFireBaseRequests(error.code);
      });
    },
    logOut() {
      signOut(auth)
        .then(() => {
          Object.keys(store.state.unsubscribe).forEach((element) => {
            this.unsubscribeElement(element);
          });
          this.setRouteVuex("Logout");
        })
        .catch((error) => {
          this.handleFireBaseRequests(error.code);
        });
    },
    handleInvalidJWT() {
      store.dispatch("setUserLogged", undefined);
      localStorage.removeItem("userLogged");
      localStorage.removeItem("token");

      signOut(auth)
        .then(() => {
          store.dispatch("setRouteVuex", "Login");
          tools.methods.executeAction({
            action: "cancel",
            confirm: true,
          });
        })
        .catch((error) => {
          this.handleFireBaseRequests(error.code);
        });
    },
    clearTimeOutCheckToken() {
      clearTimeout(expirationTimeOut);
    },
    setTimeoutExpireToken(time) {
      expirationTimeOut = setTimeout(()=> {
        this.expireSession();
      }, time)
    },
    expireSession() {
      let statusConfig = {
        color: 'error',
        icon: 'mdi-alert-circle',
        msg: i18n.t('expiredSession')
      };
      store.dispatch('setHttpStatus', statusConfig);
      this.logOut();
    },
    async checkIfSetStateValidatingToken(value, forceRefreshToken = false) {
      if (!value) {
        return true;
      }
      const isValidToken = await this.checkIfTokenIsValid(true, forceRefreshToken);
      if (!isValidToken) {
        this.expireSession();
        return false; 
      }
      return true;
    },
    async checkSession() {
      const isValidToken = await this.checkIfTokenIsValid();
      if (!isValidToken) this.expireSession();
    },
    compareTokensAuthAndVuex() {
      const accessTokenVuex = store.state.userLogged?.accessToken;
      const accessTokenAuthCurrentUser = auth.currentUser?.stsTokenManager.accessToken;
      if (auth.currentUser && (accessTokenVuex !== accessTokenAuthCurrentUser)) {
        const updatedUserLogged = {
          ...store.state.userLogged,
          ...auth.currentUser,
        }

        store.dispatch("setUserLogged", updatedUserLogged);
        localStorage.setItem('token', accessTokenAuthCurrentUser);
      }
    },
    async checkIfTokenIsValid(refreshToken = false, forceRefreshToken = false) {
      this.compareTokensAuthAndVuex();
      const expirationTime = store.state.userLogged?.stsTokenManager.expirationTime ?? 0;
      this.clearTimeOutCheckToken(); 
      const currentTime = Date.now(); 
      const timeUntilExpiration = expirationTime - currentTime;
      const thirtyMinutesInMillis = 30 * 60 * 1000;
      if (timeUntilExpiration > 0) {
        if (refreshToken && (forceRefreshToken || (timeUntilExpiration <= thirtyMinutesInMillis))) {
          const expirationTimeRefreshedToken = await this.refreshTokenAndGetExpirationTime();
          const expirationTimeAfterRefresh = expirationTimeRefreshedToken - Date.now();
          this.setTimeoutExpireToken(expirationTimeAfterRefresh);
          return true;
        } else {
          this.setTimeoutExpireToken(timeUntilExpiration);
          return true;
        }
      } else {
        return false;
      }
    },
    async refreshTokenAndGetExpirationTime() {
      try {
        const idTokenResult = await auth.currentUser.getIdTokenResult(true);
        const expirationTimeRefreshToken = idTokenResult.expirationTime;
        const expirationDate = new Date(expirationTimeRefreshToken);
        const expirationInMillis = expirationDate.getTime();

        const updatedUserLogged = {
          ...store.state.userLogged,
          ...auth.currentUser,
        }
        store.dispatch("setUserLogged", updatedUserLogged);

        localStorage.setItem('token', idTokenResult.token);
        return expirationInMillis;
      } catch (error) {
          return 0;
      }
    },
    subscribeIsUserLogged() {
      onAuthStateChanged(auth, (user) => {
        if (!user) {
          this.$router.history.current.name !== "Login" &&
            this.$router.history.current.name !== "Logout" &&
            store.dispatch("setRouteVuex", "Login");
          this.setUserLogged(undefined);
          localStorage.removeItem("userLogged");
          localStorage.removeItem("token");
          localStorage.removeItem("actualGroup");
          store.dispatch("setActualGroup", undefined);
          store.dispatch("setDataView", undefined);
          store.dispatch("setProcessData", undefined);
        } else {
          
          if (this.$router.history.current.name === "Login")
            store.dispatch("setRouteVuex", "Home");
          store.dispatch("setUserLogged", {
            ...store.state.userLogged,
            ...user,
          });
          store.dispatch("setLoadingPage", true);
          this.subscribeOnSnapshotUser(user.uid);
          store.dispatch("setLoadingPage", false);
        }
      });
    },
    suscribeToAllViews() {
      Object.keys(store.state.unsubscribe).forEach((element) => {
        if (
          element !== "userLogged" &&
          element !== "Detail" &&
          element !== "DetailDictionary"
        ) {
          this.unsubscribeElement(element);
        }
      });

      VIEWS.viewsScheme.forEach(({ get, meta, name }) => {
        if (!meta?.parent && name !== "ProcessHistory" && name !== "Home") {
          get.forEach((item) => {
            firebaseTools.methods[item.get](
              item.collection,
              item.query || null,
              true
            );
          });
        }
      });
    },
    async updateUserPhoto(photo) {
      try {
        await updateProfile(auth.currentUser, {
          photoURL: photo
        })
        return true
      } catch (error) {
        return false
      }
    },
    downloadStorageFile(fileRef, fileName) {
      return getDownloadURL(fileRef)
        .then(async (url) => {
          await this.downloadFile(
            url,
            "get",
            fileName,
            null
          )
        })
        .catch(() => {
          this.setShowSnackBar({
            color: "error",
            msg: i18n.t("errorDownloadFile"),
            icon: "mdi-alert-circle",
          });
        })
    },
    handleFireBaseRequests(code, element = null, action = null) {
      const actualView =
        store.state.actualView?.parent || store.state.actualView;
      switch (code) {
        case "auth/user-not-found":
          return i18n.t("loginUserError");
        case "auth/wrong-password":
          return i18n.t("loginPswdError");
        case "auth/invalid-email":
          return i18n.t("loginEmailError");
        case "permission-denied":
          store.dispatch("setRouteVuex", "NotAllowed");
          break;
        case "error-CRUD":
          store.dispatch("setShowSnackBar", {
            color: "error",
            icon: "mdi-alert-circle",
            msg: i18n.t(`error.${action}.${actualView.name}`, {
              element: element,
            }),
          });
          break;
        case "success-CRUD":
          store.dispatch("setShowSnackBar", {
            color: "success",
            icon: "mdi-check-circle",
            msg: i18n.t(`success.${action}.${actualView.name}`, {
              element: element,
            }),
          });
          break;
        case "success-process":
          if (action === "edit") return;
          if (actualView.name === "ProcessHistory")
            store.dispatch("setShowSnackBar", {
              color: "info",
              icon: "mdi-information",
              msg: i18n.t("runningProcess"),
            });
          else if (actualView.name !== "Home")
            store.dispatch("setShowSnackBar", {
              color: "info",
              icon: "mdi-information",
              msg: i18n.t("runningProcess"),
              link: { to: "ProcessHistory", text: i18n.t("seeProcessStatus") },
            });
          break;
        case "auth/internal-error":
          store.dispatch("setShowSnackBar", {
            color: "error",
            icon: "mdi-alert-circle",
            msg: i18n.t(`error.emailBlocked`),
          });
          break;
        case "finish-process":
          if (actualView.name === "ProcessHistory")
            store.dispatch("setShowSnackBar", {
              color: "success",
              icon: "mdi-check-circle",
              msg: i18n.t("finishProcess"),
            });
          else if (actualView.name !== "Home")
            store.dispatch("setShowSnackBar", {
              color: "success",
              icon: "mdi-check-circle",
              msg: i18n.t("finishProcess"),
              link: { to: "ProcessHistory", text: i18n.t("seeFileProcesses") },
            });
          break;
        case "error-process":
          if (actualView.name === "ProcessHistory")
            store.dispatch("setShowSnackBar", {
              color: "error",
              icon: "mdi-alert-circle",
              msg: i18n.t(`error.add.ProcessHistory`),
            });
          else if (actualView.name !== "Home")
            store.dispatch("setShowSnackBar", {
              color: "error",
              icon: "mdi-alert-circle",
              msg: i18n.t(`error.add.ProcessHistory`),
              link: { to: "ProcessHistory", text: i18n.t("seeFileProcesses") },
            });
          break;
        default:
          store.dispatch("setShowSnackBar", {
            color: "error",
            icon: "mdi-alert-circle",
            msg: i18n.t("unexpectedError"),
          });
          break;
      }
    },
  },
};
